import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import Logger from "src/app/modules/core/services/logger-service/logger.service";
import { API_BASE_URLS } from "../config/api.config";
import { SessionService } from "src/app/services/session.service";
import { PermissionService } from "../services/permission.service";
import { AuthService } from "../services/auth.service";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	private activeTenantId;
	constructor(private readonly injector: Injector,
		private readonly sessionService: SessionService,
		private readonly permissionService: PermissionService,
		private readonly authService: AuthService
	) {
		this.authService.activeTenantChange.subscribe((activeTenant) => {
			if (activeTenant) {
				this.activeTenantId = activeTenant.id;
			}
		})
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Logger.info("Hollow interceptor" + request);
		// if ((request.method === "POST" || request.method === "PUT" || request.method === "DELETE")
		// 	&& !request.url.includes(API_BASE_URLS.cumulocityExtension)) {
		// 	let token;
		// 	const cookieArr = document.cookie.split(";");
		// 	for (const cookie of cookieArr) {
		// 		let c = cookie;
		// 		while (c.charAt(0) === " ") {
		// 			c = c.substring(1, c.length);
		// 		}
		// 		if (c.indexOf("XSRF-TOKEN=") === 0) {
		// 			token = c.substring("XSRF-TOKEN=".length, c.length);
		// 		}

		// 	}
		// 	Logger.info("Token" + token);
		// 	request = request.clone({
		// 		setHeaders: { "X-XSRF-TOKEN": token }
		// 	});
		// }
		
		if (request.url.includes("pm-api/")) {
			const update: any = {
				setHeaders: { "Authorization": "Bearer " + this.sessionService.getAccessToken() },
			};
			// console.log("bearer",request.url, this.sessionService.getAccessToken());
			
			if (this.permissionService.hasPermissionAcrossRoles("SAD") && this.activeTenantId) {
				update.setParams = { "tenantId": this.activeTenantId }
			}
			request = request.clone(update);
		}
		return next.handle(request);
	}
}
