import { Injectable } from "@angular/core";
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpXsrfTokenExtractor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const urls = [];

export function MockData(data) {
	urls.push(data);
}

@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {
	constructor(private readonly xsrfToken: HttpXsrfTokenExtractor) { }

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (environment.production) {
			request = request.clone({
				setHeaders: {
					"X-XSRF-TOKEN": this.xsrfToken.getToken()
				}
			});
		}
		for (const element of urls) {
			if (
				request.url.startsWith(element.url) &&
				request.method === element.method
			) {
				return next.handle(
					new HttpRequest(
						"GET",
						`../../../../assets/mock-api${element.data}`
					)
				);
			}
		}
		return next.handle(request);
	}
}
