const PROTOCOL = "https";
const HOST = "9wsmte55w7.execute-api.eu-central-1.amazonaws.com";
export const environment = {
	production: true,
	mock: false,
	test: false,
	baseUrl: `${PROTOCOL}://${HOST}/pm-api`,
	isDebugMode: false,
	serviceConfigurations: {
		ruleService: {
			name: "ruleservicedev",
			version: "v1",
			ruleServiceUrl: "rules-service"
		},
		configurationService: {
			name: "configservicedev",
			version: "v1",
			configurationServiceUrl: "configuration"
		},
		dataService: {
			name: "dataservicedev",
			version: "v1",
			aggregationServiceUrl: "data/aggregation",
			miscellaneousServiceUrl: "data/mdsp-master-data",
			diagnosticServiceUrl: "data/agent-diagnostic",
			detailedSearchServiceUrl: "data"
		}
	},
	clientId: 'XJ0IZAznHN0GqOjGbS9eQLWkGyUvmFdF', //DEV INSTANCE clientId
	// clientId: 'meW7gfefNUDMmn0ZXAUTwOkpVhqwkkPG', // DEMO INSTANCE clientId
	cookieUrl: 'https://assets.adobedtm.com/5dfc7d97c6fb/9f2b9af520c3/launch-b25a29efa7a1-staging.min.js',
	issuer: 'https://siemens-qa-bt-015.eu.auth0.com/',
	authProfileUrl: '',
	msIssuer: 'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
	product: 'PortfolioManager',
	siemensIdBaseUrl: 'https://login-qa.siemens.com',
	licenseApiUrl: "https://subscription.horizonint.cloud/api/v1",
	//   clientId: "QUvff2xkUnSGPCOXCjKZ1vnrv5odG1Cf",
	apiUrl: "https://user.horizonint.cloud/api/v1",
	apiUrlV2: "https://prodint.um.user.horizonint.cloud/api/v2",
	subscriptionUrl: "https://product-int.subscription.horizonint.cloud/api/v2",
	audience: "https://horizonint.cloud",
	tenant: "main-tenant-oidc",
	profileUrl: "https://uss.login-int.siemens.com/userprofile",
	appSwitcherSource: "https://product-int.webapp.horizonint.cloud/api/v1/me/apps",
	accountManagerAppUrl: "https://account.apps.horizonint.cloud",
	assetManagerAppUrl: "https://assets.apps.horizonint.cloud",
	aodsUrl: "https://product-int.aods.horizonint.cloud/api/v2",
	appName: 'Portfolio Manager'
};
