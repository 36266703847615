import { NgModule, APP_INITIALIZER, InjectionToken } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreService } from "./services/core.service";
import { ThemeService } from "./services/theme.service";
import { TranslateService } from "./services/translate.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { HttpMockRequestInterceptor } from "./services/mock-data-service";
import { HttpRequestInterceptor } from "./interceptors/interceptor";
import { translateFactory } from "./factories/translate-factory";
import { AuthService } from "./services/auth.service";
import { configurationFactory } from "./factories/configuration-factory";
import { AppConfigService } from "./services/app-config.service";
import { UserResolve } from "./resolvers/user.resolve";
import { PermissionService } from "./services/permission.service";
import { SharedModule } from "../shared/shared.module";
import { AppLoaderService } from "./services/app-loader.service";
import { AdminResolve } from "./resolvers/admin.resolve";
import { HttpErrorInterceptor } from "./interceptors/http-error-interceptor";
import { SessionService } from "./services/session.service";
import { LocaleService } from "./services/locale.service";

export const isMock = environment.mock;
const configDeps = new InjectionToken<(() => () => {})[]>("configDeps");

@NgModule({
	declarations: [],
	imports: [CommonModule, HttpClientModule, SharedModule],
	providers: [
		AuthService,
		AppConfigService,
		{
			provide: APP_INITIALIZER,
			useFactory: configurationFactory,
			multi: true,
			deps: [AuthService, AppConfigService, configDeps, AppLoaderService, TranslateService]
		},
		{
			provide: configDeps,
			useFactory: translateFactory,
			deps: [TranslateService]
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: isMock
				? HttpMockRequestInterceptor
				: HttpRequestInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true
		},
		UserResolve,
		CoreService,
		TranslateService,
		ThemeService,
		PermissionService,
		AppLoaderService,
		AdminResolve,
		SessionService,
		LocaleService
	]
})
export class AppCoreModule { }
