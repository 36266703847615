import { Component } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import {
	SiCookiesService
} from '@building-x/common-ui-ng';
@Component({
	templateUrl: './landing.component.html'
})
export class LandingComponent {
	availableLanguages = this.settingsService.availableLanguages;

	constructor(private settingsService: SettingsService, private readonly cookieService: SiCookiesService) {
		localStorage.removeItem('activeRole');
	}
}
