import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IUser } from "../interfaces/auth/user.interface";
import { Subject } from "rxjs";
import { IRoleSummary } from "./../interfaces/auth/role-summary.interface";
import { map } from "rxjs/operators";
import { ENDPOINTS } from "./endpoints";
import { ITenant } from "../interfaces/tenant/tenant.interface";
import { TenantService } from "../../admin/tenant-management/services/tenant.service";

@Injectable()
export class AuthService {
	userData: IUser;
	activeRole: IRoleSummary;
	private readonly _isRoleChangeSubject = new Subject<IRoleSummary>();
	public isRoleChange$ = this._isRoleChangeSubject.asObservable();
	private readonly _userDataChangedSubject = new Subject<IUser>();
	public userDataChanged$ = this._userDataChangedSubject.asObservable();
	private activeTenant: ITenant;
	private readonly _activeTenantSubject = new Subject<ITenant>();
	public activeTenantChange = this._activeTenantSubject.asObservable();
	public isRoleChangePopUpActive=false;
	constructor(
		private readonly _http: HttpClient,
		private readonly tenantService: TenantService
	) { }

	getLoggedInUser = (emailId: string) => {
		return this._http.get<IUser>(`${ENDPOINTS.user_logged_in.url}/${emailId}`)
			.pipe(map((user: IUser) => {
				user.roles = user.listOfRoles;
				this.userData = user;
				if (localStorage.getItem('activeRole')&&!['undefined'].includes(localStorage.getItem('activeRole')) && this.checkRoleExists(JSON.parse(localStorage.getItem('activeRole')))) {
					this.setActiveRole(JSON.parse(localStorage.getItem('activeRole')));
				} else {
					this.setActiveRole(this.getDefaultRole());
				}
				this._userDataChangedSubject.next(user);
				return user;
			}));
	}

	getUserRoles = (userId) => {
		return this._http.get(`${ENDPOINTS.user_role.url}?userId=${userId}`);
	}

	updateUserImage(fileFormData: any) {
		return this._http.put(`${ENDPOINTS.user_put.url}/${this.getUser().id}/upload`, fileFormData);
	}

	setUserDefaultRole(userDetails: any) {
		return this._http.put(`${ENDPOINTS.user_default_role.url}?userId=${this.getUser().id}`, userDetails);
	}

	getDefaultRole() {
		return this.userData?.roles.find((roleData: IRoleSummary) => roleData.isDefault === true);
	}

	setDefaultRole(id: number) {
		this.userData.roles.forEach(roleData => {
			roleData.isDefault = false;
			
			if (roleData.id === id) {
				roleData.isDefault = true;
			}
		});
	}

	setActiveRole(roleData: IRoleSummary) {
		this.activeRole = roleData;
		localStorage.setItem('activeRole', JSON.stringify(roleData));
		this._isRoleChangeSubject.next(roleData);
	}

	getActiveRole() {
		return this.activeRole;
	}

	checkRoleExists(role): boolean {
		let found = false;
		for (const userRole of this.userData.listOfRoles) {
			if (userRole.id == role.id) {
				found = true;
				break;
			}
		}
		return found;
	}

	getUser() {
		return this.userData;
	}

	authInitializer(emailId: string) {
		return this.getLoggedInUser(emailId)
			.pipe(map(async () => {
				let flag = false;
				if (this.userData?.roles) {
					for (const role of this.userData?.roles) {
						for (const scope of role?.listOfScopes) {
							if (scope.code === "SAD") {
								flag = true;
								break;
							}
						}
						if (flag) {
							break;
						}
					}
				}
				if (flag) {
					await this.getTenantDetails();
				}
			})).toPromise();
	}

	getTenantDetails = async () => {
		const tenantDetials = await this.tenantService.getAllTENANTs({}).toPromise();
		this.setActiveTenant(tenantDetials[0]);
	}

	setActiveTenant(tenant: any) {
		this.activeTenant = tenant;
		this._activeTenantSubject.next(tenant);
	}

	isSuperAdmin() {
		let isSuperAdmin = false;
		if (this.getActiveRole()?.listOfScopes.find((scope) => scope.code == "SAD")) {
			isSuperAdmin = true;
		}
		return isSuperAdmin;
	}

	isAdmin() {
		let isAdmin = false;
		if (this.getActiveRole()?.listOfScopes.find((scope) => scope.category == "ADMIN")) {
			isAdmin = true;
		}
		return isAdmin;
	}
}
