import { environment } from "src/environments/environment";
import { MockData } from "src/app/modules/core/services/mock-data-service";
import { API_BASE_URLS } from "src/app/modules/core/config/api.config";

const CONFIGURATION_URL = `${environment.baseUrl}/${API_BASE_URLS.configurationService}`;

export const ENDPOINTS = {
	tenant_summary: {
		url: CONFIGURATION_URL + "/tenant"
	},
	tenant_by_id: {
		url: "/tenant/4"
	},
	customer_summary: {
		url: CONFIGURATION_URL + "/customer"
	},
	customer_by_id: {
		url: "/customer/4"
	},
	metric_group_by_id: {
		url: CONFIGURATION_URL + "/metric-type-group/5",
		method: "GET",
		data: "/admin-module/edit-section/metrictype-group-by-id.json"
	},
	metric_group_list: {
		url: CONFIGURATION_URL + "/metric-type-group",
		method: "GET",
		data: "/admin-module/list-section/metrictype-group-list.json"
	},
	library_by_id: {
		url: CONFIGURATION_URL + "/library/5",
		method: "GET",
		data: "/admin-module/edit-section/library-by-id.json"
	},
	library: {
		url: CONFIGURATION_URL + "/library",
		method: "GET",
		data: "/admin-module/list-section/library-list.json"
	},
	unit: {
		url: CONFIGURATION_URL + "/unit",
		method: "GET",
		data: "/admin-module/list-section/library-list.json"
	},
	metric_type_summary: {
		url: CONFIGURATION_URL + "/metric-type",
		method: "GET",
		data: "/admin-module/list-section/metric-type-list.json"
	},
	metric_type_by_id: {
		url: CONFIGURATION_URL + "/metric-type/6",
		method: "GET",
		data: "/admin-module/edit-section/metric-type-by-id.json"
	},
	metric_type: {
		url: CONFIGURATION_URL + "/metrictype-type",
		method: "GET",
		data: "/admin-module/list-section/metri-type.json"
	},
	control_group_list: {
		url: CONFIGURATION_URL + "/control-point-group/analytics",
		method: "GET",
		data: "/admin-module/list-section/control-group-list.json"
	},
	control_group_by_id: {
		url: CONFIGURATION_URL + "/control-point-group/4",
		method: "GET",
		data: "/admin-module/edit-section/control-group.json"
	},
	permission_group_by_id: {
		url: CONFIGURATION_URL + "/permission-group/1",
		method: "GET",
		data: "/admin-module/edit-section/permission-group-by-id.json"
	},
	permission_group_list: {
		url: CONFIGURATION_URL + "/permission-group",
		method: "GET",
		data: "/admin-module/list-section/permission-group-list.json"
	},
	permission_list: {
		url: CONFIGURATION_URL + "/permission",
		method: "GET",
		data: "/admin-module/list-section/permission-list.json"
	},
	city_profile_base_list: {
		url: CONFIGURATION_URL + "/city-profile/base",
		method: "GET",
		data: "/admin-module/list-section/city-profile-base-list.json"
	},
	usecase_base_list: {
		url: CONFIGURATION_URL + "/portfolio",
		method: "GET",
		data: "/admin-module/list-section/usecase-base-list.json"
	}
};

if (environment.mock) {
	for (const prop in ENDPOINTS) {
		if (ENDPOINTS.hasOwnProperty(prop)) {
			MockData(ENDPOINTS[prop]);
		}
	}
}

