import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import localeGerman from '@angular/common/locales/de';
import localeIndian from '@angular/common/locales/en-IN'
import localeUS from '@angular/common/locales/en'
import { Subject } from 'rxjs';


@Injectable()
export class LocaleService {
	private activeLocale = 'en-US';
	private readonly localeChanged = new Subject();
	public localeChanged$ = this.localeChanged.asObservable();
	constructor() { }

	get currentLocale() {
		return this.activeLocale;
	}

	setActiveLocale(localeId: string) {
		switch (localeId) {
			case 'de-DE':
				registerLocaleData(localeGerman);
				break;
			case 'en-US':
				registerLocaleData(localeUS);
				break;
			case 'en-IN':
				registerLocaleData(localeIndian);
				break;
		}
		this.activeLocale = localeId;
		this.localeChanged.next(localeId);
	}

}
