import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ITheme } from "../interfaces/theme/theme.interface";
import { APP_THEMES } from "../config/theme.config";
import { Observable, BehaviorSubject } from "rxjs";

const EXPO_THEMES = "expo-themes";
const LANGUAGE_DIRECTION = "expo-dir";
const LTR = "ltr";
const RTL = "rtl";
const THEME_CUSTOM = "theme-custom";
const BT_RTL = "bootstrap-rtl";
const ATTRIBUTE_DIR = "dir";

@Injectable()
export class ThemeService {
	allThemes: ITheme[] = APP_THEMES;
	activeTheme: ITheme;
	activeLanguageDirection = LTR;
	private readonly themeSubject = new BehaviorSubject<any>(null); //this.activeTheme

	constructor(@Inject(DOCUMENT) private readonly document: Document) { }

	init() {
		this.loadInitialTheme();
		this.loadLanguageDirection();
	}

	loadInitialTheme(): void {
		let themes: any = localStorage.getItem(EXPO_THEMES);
		if (!themes) {
			const defaultTheme = this.allThemes.find(theme => theme.isDefault);
			this.changeTheme(defaultTheme.name);
		} else {
			themes = JSON.parse(themes);
			let preferredTheme = themes.find(theme => theme.isActive);
			if (!preferredTheme) {
				preferredTheme = themes.find(theme => theme.isDefault);
			}
			this.changeTheme(preferredTheme.name);
		}
	}

	loadLanguageDirection() {
		const languageDirection: string = localStorage.getItem(LANGUAGE_DIRECTION);
		if (languageDirection && languageDirection === RTL) {
			this.changeToRtl();
		}
	}

	changeTheme(themeName: string): void {
		themeName = themeName.toLowerCase();
		let themes: any = localStorage.getItem(EXPO_THEMES);
		if (!themes) {
			themes = this.allThemes;
			themes.forEach(theme => {
				if (theme.name === themeName) {
					theme.isActive = true;
					this.activeTheme = theme;
				}
			});
		} else {
			themes = JSON.parse(themes);
			themes.forEach(theme => {
				if (theme.name === themeName) {
					theme.isActive = true;
					this.activeTheme = theme;
				} else {
					theme.isActive = false;
				}
			});
		}
		localStorage.setItem(EXPO_THEMES, JSON.stringify(themes));
		this.themeSubject.next({ name: themeName });
		(this.document.getElementById(THEME_CUSTOM) as HTMLLinkElement).href = `${themeName}.css`;
	}

	getActiveTheme(): ITheme {
		return this.activeTheme;
	}

	getThemeChangeNotification(): Observable<any> {
		return this.themeSubject.asObservable();
	}

	changeToRtl() {
		this.document.body.setAttribute(ATTRIBUTE_DIR, RTL);
		let bootstrapRtlLink = this.document.getElementById(BT_RTL);
		const customThemeLink = this.document.getElementById(THEME_CUSTOM);
		if (!bootstrapRtlLink) {
			bootstrapRtlLink = this.document.createElement("link");
			bootstrapRtlLink.setAttribute("id", BT_RTL);
			bootstrapRtlLink.setAttribute("href", "bootstrap-rtl.css");
			bootstrapRtlLink.setAttribute("rel", "stylesheet");
			bootstrapRtlLink.setAttribute("type", "text/css");
			this.document.head.insertBefore(bootstrapRtlLink, customThemeLink);
		} else {
			bootstrapRtlLink.removeAttribute("disabled");
		}
		localStorage.setItem(LANGUAGE_DIRECTION, RTL);
		this.activeLanguageDirection = RTL;
	}

	changeToLtr() {
		this.document.body.setAttribute(ATTRIBUTE_DIR, LTR);
		this.document.getElementById(BT_RTL).setAttribute("disabled", "true");
		localStorage.setItem(LANGUAGE_DIRECTION, LTR);
		this.activeLanguageDirection = LTR;
	}

	getActiveLanguageDirection() {
		return this.activeLanguageDirection;
	}

	getColorByName(name) {
		return getComputedStyle(document.documentElement).getPropertyValue(name);
	}
}
