<div class="modal-header">
	<div class="title">
		<si-icon icon="element-activate-user" size="display-4" />
		<h4 class="modal-title" id="sample-modal-title"><span>{{ "CORE.HEADER:CHANGE_ROLE_HEADER_TEXT" | translate
				}}</span></h4>
	</div>
	<button type="button" class="btn btn-circle btn-sm btn-ghost element-cancel" aria-label="Close modal"
		(click)="handleClose()">
	</button>
</div>
<div class="modal-body">
	<div class="role-change-container">
		<div class="role-change-parent">
			<div class="role-change-classifications">
				<div class="role-change-classification-card" [class.active-card]="selected?.id === roleData.id"
					*ngFor="let roleData of roles" (click)="onSelectRole(roleData)">
					<si-icon
						[ngClass]="{'role-image-active': selected?.id === roleData.id, 'role-image-inactive': selected?.id !== roleData.id}"
						icon="element-user" [color]="selected?.id === roleData.id ? 'text-primary' : 'text-secondary'"
						size="display-1" />
					<div class="role-name">{{roleData.name}}</div>
					<si-icon [icon]="selected?.id === roleData.id ? 'element-circle-filled' : 'element-not-checked'"
						[color]="selected?.id === roleData.id ? 'text-primary' : 'text-secondary'"
						stackedIcon="element-state-tick"
						[stackedColor]="selected?.id === roleData.id ? 'status-success-contrast' : 'text-secondary'"
						size="display-2" />
				</div>
			</div>
		</div>
		<div class="role-change-action-container">
			<div class="form-check">
				<label>
					<input type="checkbox" class="form-check-input" [checked]="selected?.isDefault ? 'checked' : ''"
						[disabled]="selected?.id === -1" (change)="defaultRoleSelection($event)" />
					<span class="form-check-label">{{ "CORE.HEADER:CHANGE_ROLE_DEFAULT_CHECKBOX_TEXT" | translate
						}}</span>
				</label>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary" [disabled]="!selected || !defaultRole ||
		(defaultRole?.id === authService.getDefaultRole()?.id && selected?.id === authService.getActiveRole()?.id)"
		(click)="save()">Apply</button>
</div>