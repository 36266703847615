import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { AuthService } from "./../services/auth.service";
import { SiModalService } from '@simpl/element-ng/modal';
import { ChangeRoleViewComponent } from "../../shared/components/change-role-view/change-role-view.component";

@Injectable()
export class UserResolve implements Resolve<any> {
	constructor(
		private readonly authService: AuthService,
		private readonly modalService: SiModalService
	) { }

	resolve() {
		return new Promise<any>((resolve, reject) => {
			if (this.authService.getUser()) {
				return this.authService.getActiveRole() ? resolve(null) : this.openRoleSelectionPopup();
			}
		});
	}

	openRoleSelectionPopup() {
		if (!this.authService.isRoleChangePopUpActive) {
			this.authService.isRoleChangePopUpActive = true;
			const initialStateData = {
				recievedFrom: "resolver"
			};
			this.modalService.show(ChangeRoleViewComponent, {
				initialState: initialStateData,
				animated: true,
				class: 'modal-lg modal-dialog-centered',
				ignoreBackdropClick: true
			});
		}

	}
}
