import { environment } from "./../../../../../environments/environment";

export const isDebugMode = environment.isDebugMode;

const noop = (): any => undefined;

type MsgType = "debug" | "info" | "warn" | "error";

export interface ILogInterface {
	debug(message: string, ...data: any[]): void;
	warn(message: string, ...data: any[]): void;
	error(message: string, ...data: any[]): void;
	info(message: string, ...data: any[]): void;
}

class Log implements ILogInterface {
	public debug(...data: any[]): void {
		this.emitLogMessage("debug", ...data);
	}
	public warn(...data: any[]): void {
		this.emitLogMessage("warn", ...data);
	}
	public error(...data: any[]): void {
		this.emitLogMessage("error", ...data);
	}
	public info(...data: any[]): void {
		this.emitLogMessage("info", ...data);
	}

	public emitLogMessage(
		msgType: MsgType,
		...data: any[]
	) {
		if (isDebugMode) {
			return console[msgType](msgType, ...data);
		} else {
			return noop;
		}
	}
}
const logMethod = new Log();
export default logMethod;
