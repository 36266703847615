import { HttpClient } from '@angular/common/http';
import { NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SI_COPYRIGHT_DETAILS, SiLandingPageModule, SiToastNotificationService, SiNavbarModule } from '@simpl/element-ng';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import { NgxUiLoaderConfig, NgxUiLoaderModule, POSITION, SPINNER } from 'ngx-ui-loader';
import { AppComponent } from './app.component';
import { AppCoreModule } from './modules/core/app-core.module';
import { LandingComponent } from './pages/landing/landing.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { Localization } from './services/localization/localization.service';
import { UserService } from './modules/admin/user-management/services/user.service';

import {
	AppConfigService,
	AuthenticationService,
	SiAppDataService, SiAppListService,
	SiemensIdAuthenticationMockService,
	SiLoginAuthModule,
	SiPermissionService,
	SiProfileService,
	SiSharedModule,
	SiThemingService
} from '@building-x/common-ui-ng';
import { SessionService } from './services/session.service';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuardService } from './modules/core/guards/auth-guard.service';
import { NgxEchartsModule } from 'ngx-echarts';

const buildTime = "1714973808131";

export const siemensIdAuthenticationFactory = (
	oAuthService: OAuthService,
	ngZone: NgZone
) => {
	if (environment.test) {
		return new SiemensIdAuthenticationMockService(ngZone);
	} else {
		return new AuthenticationService(
			oAuthService,
			environment.issuer,
			environment.msIssuer,
			environment.profileUrl,
			environment.apiUrlV2 // to support cognito
		);
	}
};

export const httpLoaderFactory = (http: HttpClient) => new MultiTranslateHttpLoader(http, [
	{ prefix: './assets/i18n/', suffix: `.json?version=${buildTime}` },
	{ prefix: './assets/i18n/imported/', suffix: `.json?version=${buildTime}` },
	{ prefix: './assets/i18n/no-translations/', suffix: `.json?version=${buildTime}` },
	{ prefix: './assets/i18n/imported/no-translations/', suffix: `.json?version=${buildTime}` }
]);

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/', '.json');
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
	bgsColor: "#00cccc",
	fgsColor: "#00cccc",
	bgsPosition: POSITION.centerCenter,
	fgsPosition: POSITION.centerCenter,
	bgsType: SPINNER.threeBounce, // background spinner type
	fgsType: SPINNER.threeBounce,
	overlayColor: "transparent",
	bgsOpacity: 0.6,
	blur: 8,
	hasProgressBar: false
};

@NgModule({
	declarations: [
		AppComponent,
		LandingComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
		SiLandingPageModule,
		SiLoginAuthModule,
		SiTranslateNgxTModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		AppCoreModule,
		SiSharedModule,
		NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		SiNavbarModule,
		AppRoutingModule
	],
	providers: [
		{
			provide: SI_COPYRIGHT_DETAILS,
			useValue: {
				startYear: 2024
			}
		},
		AppConfigService,
		SiAppDataService,
		SiAppListService,
		SiPermissionService,
		// ModalModule.forRoot() forgot to provide this
		SiProfileService,
		SiThemingService,
		Localization,
		UserService,
		SiToastNotificationService,
		{
			provide: AuthenticationService,
			useFactory: siemensIdAuthenticationFactory,
			// eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays
			deps: [OAuthService, NgZone]
		},
		AuthGuardService
	],
	exports: [],
	bootstrap: [AppComponent]
})

export class AppModule {
	constructor(
		sessionService: SessionService
	) {
		sessionService.initialize();
	}
}
