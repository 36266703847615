import { environment } from "src/environments/environment";
import { MockData } from "./mock-data-service";
import { API_BASE_URLS } from "src/app/modules/core/config/api.config";

const CONFIGURATION_URL = `${environment.baseUrl}/${API_BASE_URLS.configurationService}`;

export const ENDPOINTS = {
	user_list: {
		url: CONFIGURATION_URL + "/user",
		method: "GET",
		data: "/admin-module/list-section/user-list.json"
	},
	user_by_id: {
		url: CONFIGURATION_URL + "/user/2",
		method: "GET",
		data: "/admin-module/edit-section/user-by-id.json"
	},
	role_by_id: {
		url: CONFIGURATION_URL + "/role/11",
		method: "GET",
		data: "/admin-module/edit-section/role-by-id.json"
	},
	role_analytics: {
		url: CONFIGURATION_URL + "/role",
		method: "GET",
		data: "/admin-module/list-section/role-list.json"
	},
	user_image_upload: {
		url: CONFIGURATION_URL + "/user/5/upload",
		method: "PUT",
		data: ""
	},
	user_default_role: {
		url: CONFIGURATION_URL + "/user-role/default-role",
		method: "PUT",
		data: ""
	},
	user_logged_in: {
		url: CONFIGURATION_URL + "/user/logged-in",
		method: "GET",
		data: "/core-module/users.json"
	},
	user_role: {
		url: CONFIGURATION_URL + "/user-role",
		method: "GET",
		data: ""
	},
	user_put: {
		url: CONFIGURATION_URL + "/user",
		method: "PUT",
		data: ""
	},
	user_get: {
		url: CONFIGURATION_URL + "/user",
		method: "GET",
		data: ""
	},
	threshold_interval: {
		url: CONFIGURATION_URL + "/threshold-interval",
		method: "GET",
		data: "/core-module/threshold-intervals.json"
	},
	threshold_list: {
		url: CONFIGURATION_URL + "/threshold",
		method: "GET",
		data: "/core-module/thresholds.json"
	},
	aggregation_types: {
		url: CONFIGURATION_URL + "/aggregation-type",
		method: "GET",
		data: "/core-module/aggregationTypes.json"
	},
	sort_types: {
		url: CONFIGURATION_URL + "/sort-type",
		method: "GET",
		data: "/core-module/sortTypes.json"
	},
	visualization_types: {
		url: CONFIGURATION_URL + "/visualization-type",
		method: "GET",
		data: "/core-module/visualizationTypes.json"
	},
	use_cases_role_2: {
		url: CONFIGURATION_URL + "/role/2/use-case",
		method: "GET",
		data: "/user-module/core/usecase.json",
	},
	city_profile_role_2: {
		url: CONFIGURATION_URL + "/role/2/city-profile",
		method: "GET",
		data: "/user-module/core/cityProfile.json"
	},
	kpi_list_role_2: {
		url: CONFIGURATION_URL + "/role/2/kpi",
		method: "GET",
		data: "/user-module/core/kpiList.json"
	},
	use_cases_role_1: {
		url: CONFIGURATION_URL + "/role/1/use-case",
		method: "GET",
		data: "/user-module/core/usecase.json",
	},
	city_profile_role_1: {
		url: CONFIGURATION_URL + "/role/1/city-profile",
		method: "GET",
		data: "/user-module/core/cityProfile.json"
	},
	kpi_list_role_1: {
		url: CONFIGURATION_URL + "/role/1/kpi",
		method: "GET",
		data: "/user-module/core/kpiList.json"
	},
	role: {
		url: CONFIGURATION_URL + "/role",
		method: "GET",
		data: ""
	},
	app_config: {
		url: CONFIGURATION_URL + "/app-config",
		method: "GET",
		data: "/assets/mock-api/core-module/app-config.json"
	},
	switch_types: {
		url: CONFIGURATION_URL + "/control-point-type",
		method: "GET",
		data: "/core-module/control-point-type.json"
	},
	profile_by_id: {
		url: CONFIGURATION_URL + "/hierarchy/parent",
		method: "GET",
		data: "/admin-module/edit-section/city-profile-by-id.json"
	},
	global_hierarchy: {
		url: CONFIGURATION_URL + "/hierarchy/all-hierarchy",
		method: "GET",
		data: "/admin-module/edit-section/city-profile-by-id.json"
	},

	element_type: {
		url: CONFIGURATION_URL + "/element-type",
		method: 'GET'
	},
	portfolio: {
		url: CONFIGURATION_URL + "/portfolio",
		method: "GET"
	},
	portfolio_media: {
		url: CONFIGURATION_URL + "/portfolio-metric",
		method: "GET"
	},
	
	media_type: {
		url: CONFIGURATION_URL + "/metric-type",
		method: "GET"
	},
	element: {
		url: CONFIGURATION_URL + "/element",
		method: "GET"
	},
	scope: {
		url: CONFIGURATION_URL + "/scope",
		method: "GET",
		data: "/admin-module/list-section/role-list.json"
	},
	unit: {
		url: CONFIGURATION_URL + "/unit",
		method: "GET"
	},
	get_root: {
		url: CONFIGURATION_URL + "/hierarchy/root",
		method: "GET",
		data: "/admin-module/edit-section/city-profile-by-id.json"
	},
	get_tenant_by_id: {
		url: CONFIGURATION_URL + "/tenant",
		method: "GET",
		data: "/admin-module/edit-section/city-profile-by-id.json"
	},
};

if (environment.mock) {
	for (const prop in ENDPOINTS) {
		if (ENDPOINTS.hasOwnProperty(prop)) {
			MockData(ENDPOINTS[prop]);
		}
	}
}
