import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { AppLoaderService } from "../services/app-loader.service";

@Injectable()
export class AdminResolve implements Resolve<any> {
	constructor(
		private readonly appLoaderService: AppLoaderService
	) { }

	resolve() {
		this.appLoaderService.stopLoader();
	}
}
