import { environment } from "src/environments/environment";

const IOTURLCONFIG = environment.serviceConfigurations;

export const API_BASE_URLS = prepareApiBaseUrls();

function prepareApiBaseUrls() {
	let operatorTenantName = "expodev";
	let tenantNameForCumulosity = "expodev";
	if (environment.production) {
		const host = window?.location?.host;
		operatorTenantName = host.split("-")[2]?.split(".")[0];
		tenantNameForCumulosity = window.location.host.split("-")[0];
	}
	return {
		// tslint:disable-next-line: max-line-length
		ruleService: `${IOTURLCONFIG.ruleService.name}-${operatorTenantName}/${IOTURLCONFIG.ruleService.version}/expo-api/${IOTURLCONFIG.ruleService.ruleServiceUrl}`,
		// tslint:disable-next-line: max-line-length
		configurationService: `${IOTURLCONFIG.configurationService.configurationServiceUrl}`,
		// tslint:disable-next-line: max-line-length
		aggregationService: `${IOTURLCONFIG.dataService.name}-${operatorTenantName}/${IOTURLCONFIG.dataService.version}/expo-api/${IOTURLCONFIG.dataService.aggregationServiceUrl}`,
		// tslint:disable-next-line: max-line-length
		miscellaneousService: `${IOTURLCONFIG.dataService.name}-${operatorTenantName}/${IOTURLCONFIG.dataService.version}/expo-api/${IOTURLCONFIG.dataService.miscellaneousServiceUrl}`,
		// tslint:disable-next-line: max-line-length
		diagnosticService: `${IOTURLCONFIG.dataService.name}-${operatorTenantName}/${IOTURLCONFIG.dataService.version}/expo-api/${IOTURLCONFIG.dataService.diagnosticServiceUrl}`,
		// tslint:disable-next-line: max-line-length
		detailedSearchService: `${IOTURLCONFIG.dataService.name}-${operatorTenantName}/${IOTURLCONFIG.dataService.version}/expo-api/${IOTURLCONFIG.dataService.detailedSearchServiceUrl}`,
		cumulocityExtension: `${tenantNameForCumulosity}.mciotextension.eu1.mindsphere.io`
	};
}
