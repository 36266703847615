import { ITheme } from "../interfaces/theme/theme.interface";

export const APP_THEMES: ITheme[] = [{
	name: "default",
	isActive: false,
	isDefault: true
}, {
	name: "dark",
	isActive: false,
	isDefault: false
}, {
	name: "light",
	isActive: false,
	isDefault: false
}];
