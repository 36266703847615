import { HttpErrorResponse } from "@angular/common/http";

export function errorHandling(e: HttpErrorResponse, buttonName?: string, message?: string) {
	document.getElementById("common-error-div")?.remove();
	
	const div = document.createElement("div");
	div.id = "common-error-div";
	div.className =
		"animated slideInLeft error-handler-app-failure has-navbar-fixed-top";
	// div.style.setProperty("margin-top", '4rem');
	const h1 = document.createElement("h1");
	h1.innerHTML = message ? message : 'Failed to load the Page';
	const childDiv = document.createElement("div");
	childDiv.innerHTML = `<div style="background:transparent; color:white;"> ${e.message}</div>`;

	const button = document.createElement("button");
	button.innerText = buttonName ? buttonName : "Refresh";
	button.className = "btn btn-primary mt-5 error-handler-app-failure--button error-handler-app-failure--fourth";

	button.addEventListener("click", () => {
		window.location.reload();
	});
	div.appendChild(h1);
	div.appendChild(childDiv);
	div.appendChild(button);
	document.body.appendChild(div);
}
