import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SessionService } from '../../services/session.service';

@Component({
  template: ''
})

export class LogoutPageComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly sessionService: SessionService
  ) {

  }

  ngOnInit() {
    this.sessionService.initialized.subscribe(signedIn => {
      if (signedIn) {
        this.sessionService.logout().subscribe();
      } else {
        this.router.navigate(['/']);
      }
    });
  }
}
